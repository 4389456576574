export const environment = {
    production: true,
    stage: "penny",
    versionPollingInterval: 1000 * 60, // minute

    apiUrlPrefix: "https://penny-api.brame-gamification.com",
    keyCloakApiUrlPrefix: "https://penny-api.brame-gamification.com/keycloak",
    campaignDataUrlPrefix: "https://penny-api.brame-gamification.com/campaigns-data",
    buildServiceApiUrlPrefix: "https://penny-api.brame-gamification.com/build",
    analyticsEndpoint: "analytics.brame-gamification.com",
    resourceServiceApiUrlPrefix: "https://penny-api.brame-gamification.com/campaign-resource",

    serverlessApiBaseUrl: "https://penny-serverless-api.brame-gamification.com",
    surveyApiUrlPrefix: "https://penny-serverless-api.brame-gamification.com/survey-game/api",
    memoryApiBaseUrl: "https://penny-serverless-api.brame-gamification.com/memory-game",
    scratchCardApiBaseUrl: "https://penny-serverless-api.brame-gamification.com/scratch-card",
    pennySpinTheWheelApiBaseUrl: "https://penny-serverless-api.brame-gamification.com/penny-stw",
    advancedSpinTheWheelApiBaseUrl: "https://penny-serverless-api.brame-gamification.com/advanced-spin-the-wheel",
    prizeManagementApiBaseUrl: "https://penny-serverless-api.brame-gamification.com/prize-management",
    pennyPathApiBaseUrl: "https://penny-serverless-api.brame-gamification.com/penny-path-v2",
    pennyPenaltyApiBaseUrl:"https://penny-serverless-api.brame-gamification.com/penny-penalty",
    pennySlotMachineApiBaseUrl: "https://penny-serverless-api.brame-gamification.com/penny-slot-machine",
    timeZonesApiBaseUrl: "https://penny-serverless-api.brame-gamification.com/time-zone",
    quizApiBaseUrl: "https://penny-serverless-api.brame-gamification.com/quiz",
    eventsApiBaseUrl: "https://penny-serverless-api.brame-gamification.com/events",

    campaignPreviewBaseUrl: "https://penny-preview.brame-gamification.com",
    campaignDataStorage: "https://penny-brame-campaign-data-storage.s3.amazonaws.com/campaign-data",

    pennyLeadsExportUrl: "https://6cgxhecgdahw7shoq7xkhrqj3a0oopml.lambda-url.eu-central-1.on.aws",
    memoryGameLeadsExportUrl: "https://hukqyxdjo3x24pgrs7mo6ow5te0padzw.lambda-url.eu-central-1.on.aws",
    pennySlotMachineLeadsExportUrl: "https://wb5js3alg6s3lfbtoyzxjmhy4i0ywhbs.lambda-url.eu-central-1.on.aws",
    pennyPathLeadsExportUrl: "https://ufrcbi7rq7b4lwmylsprvz4e2m0iaxfs.lambda-url.eu-central-1.on.aws",
    advancedSpinTheWheelExportUrl: "https://rbjbw2sw3lluouwga3kvp5uwj40bmmkp.lambda-url.eu-central-1.on.aws",
    pennyPenaltyLeadsExportUrl: "https://mg4w4vtafxkbaeexol6zlmteqe0zhnjc.lambda-url.eu-central-1.on.aws",

    integrationApp: {
        selfHosted: false,
        authEndpoint: "https://penny-serverless-api.brame-gamification.com/integration-app",
        approvedIntegrations: [
            { key: "salesforce", flowKey: "test-flow" },
            { key: "hubspot", flowKey: "test-flow" },
            { key: "mailchimp", flowKey: "test-flow" },
            { key: "dynamics-crm", flowKey: "test-flow" },
            { key: "sheets", flowKey: "test-flow" },
        ],
    },

    isDemoEnv: false,
    enableDemo:false,

    cookies: {
        brame_at: "brame_at",
        refresh_brame_at: "refresh_brame_at",
        session: "SESSION",
    },
    contextKeyName: "brame-context",
    // eslint-disable-next-line max-len
    fusionChartsKey: "PtB2dF-7wB4C5C3D5A3G3F3A2B8B7D2B3F6tgoD3B1d1fdzoG4I3I-7jD-17A3D5B3D5D4B3J4C3D7C5C2B4A4G1D2G1rmoE6E1C4gI4B3C8zyhD3D2E3vraA4B2C2zbnD1H4B3A5B3F4F3H3G4A19A32aikC6D3RH4moA-31A7C8A3wfA2IC1C5rA-16mD2D5F1A2B2B7A3D6A3C2E4E2D2C3u==",
    backendAnalyticsEvents: {
        SUPPORTED_GAMES: [
            "quiz",
            "memory-game",
            "scratch-card",
            "survey",
        ],
        SUPPORTED_EVENTS: ["game_started", "game_finished", "lead_created"],
    },

    // Feature flags
    feautreFlagAppIdentifier: "3t9lzc2",
    feautreFlagProfileIdentifier: "dx3852r",
    feautreFlagEnvIdentifier: "rk5m3lk",
    featureFlagClientConfig: {
        region: "eu-central-1",
        credentials: {
            accessKeyId: "AKIATOHVODOV3D2XD7OV",
            secretAccessKey: "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",
        },
    },
    leadsExports: {
        endpoint: 'https://segf2gfhmrovkfbfx7ntljrm340zyurm.lambda-url.eu-central-1.on.aws/', // update once we have export lambda public URL for penny env
    },
    configCat: {
        sdkKey: 'configcat-sdk-1/pKDcCBJx0k2HOFPyJbWnQg/fBafCf5Sxkm80A0JcC7lmg',
    },
};
